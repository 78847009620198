import { getIsNav4Enabled } from '@confluence/nav4-enabled';

import { scrollToElementInEditor } from './editorAnnotations';

export const CREATE_COMMENT_SELECTION_CLASS = 'inline-create-comment-selection';
export const HIGHLIGHT_CLASS = 'inline-highlight';
export const ACTIVE_CLASS = 'active-highlight';

type CommentDataType = {
	commentIndex: number | undefined;
	totalComments: number | undefined;
};

type CommentElement = HTMLElement | null | undefined;

export type ScrollCommentIntoViewType = {
	commentElement: CommentElement;
	isFabricPage?: boolean;
	useInstantScroll?: boolean;
	isAbove?: boolean;
	commentThreadContainerId?: string;
	isCommentsPanel?: boolean;
};

export const getCommentIndexAndCount = () => {
	const commentData: CommentDataType = {
		commentIndex: undefined,
		totalComments: undefined,
	};

	if (
		window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__ &&
		window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['focusedComment']
	) {
		commentData['commentIndex'] = Number(
			window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['focusedComment']['position'],
		);
		commentData['totalComments'] = Number(
			window.__SSR_INLINE_COMMENTS_EVENTS_CAPTURE__['focusedComment']['totalComments'],
		);
	}

	return commentData;
};

export const getDistinctMarks = (pageMode: 'view' | 'edit') => {
	const marks = Array.from(document.querySelectorAll(getInlineMarkSelector(pageMode))).map(
		(node) => {
			if (pageMode === 'edit') {
				return (node as HTMLElement).id;
			} else {
				return getMarkerRef(node as HTMLElement);
			}
		},
	);

	/* In case of overlapping comments there will be multiple tags with the same markerRefs. We need to remove
  duplicates in that case so we can navigate between comments correctly */
	return new Set(marks);
};

/* Gets Sorted MarkerRefs in DOM and filters ones returned by back-end API for Unresolved comments */
export const getSortedMarkerRefs = (
	markerRefList: string[],
	pageMode: 'view' | 'edit' = 'view',
) => {
	const distinctMarks = [...getDistinctMarks(pageMode)];

	return distinctMarks.filter((mark) => mark && markerRefList.includes(mark)) as string[];
};

// WS-2618 We have old macros that still render out tiny comments, so changing selector to look for both
export const getInlineMarkSelector = (pageMode = 'view') => {
	if (pageMode === 'edit') {
		return "[annotationtype='inlineComment']";
	} else {
		return '[data-mark-annotation-type="inlineComment"], .inline-comment-marker';
	}
};

export const clearActiveHighlight = () => {
	const activeHighlights = document.querySelectorAll(`.${ACTIVE_CLASS}`);

	activeHighlights.forEach((activeHighlight) => {
		activeHighlight.classList.remove(ACTIVE_CLASS);
	});
};

const getNextTextNodeOld = (node?: Node | null, boundingParentNode?: Node | null) => {
	let currentNode = node;
	let nextTextNode: Node | null | undefined = currentNode?.nextSibling;

	// If the node has no next sibling, grab it's parent's next sibling until we find a node
	while (!nextTextNode) {
		nextTextNode = currentNode?.parentElement?.nextSibling;

		if (nextTextNode) {
			// WS-2551 - If the bounding parent exists and we're out of bounds of it, we're done looking
			if (boundingParentNode && !boundingParentNode.contains(nextTextNode)) {
				nextTextNode = null;
				break;
			}

			// If we've landed on a text node, then break out
			if (nextTextNode.nodeType === Node.TEXT_NODE) {
				break;
			}

			// If the node exists and it's a hard break (<br />), we do one of two things:
			//   1. If it has a bounding parent, break out because the <br /> signifies the end of the selection
			//   2. If not, then move to the next sibling because we don't count hard breaks in selections
			if ((nextTextNode as HTMLElement)?.tagName === 'BR') {
				if (boundingParentNode) {
					break;
				} else {
					nextTextNode = nextTextNode.nextSibling;
				}
			}
		} else {
			// If the text node we tried to access is null, we need to jump up a generation
			currentNode = currentNode?.parentNode;
		}
	}

	// Search for the first text node in the next node
	while (nextTextNode && nextTextNode.nodeType !== Node.TEXT_NODE) {
		nextTextNode = nextTextNode.firstChild;
	}

	return nextTextNode;
};

export const getNextTextNode = (
	currentNode?: Node | null,
	boundingNode?: Node | null,
	isInlineMark?: boolean,
): Node | null | undefined => {
	let nextTextNode: Node | null | undefined = isInlineMark ? currentNode : currentNode?.nextSibling;

	while (nextTextNode && nextTextNode.nodeType !== Node.TEXT_NODE) {
		// If the text node is the bounding node, return that node
		if (boundingNode && nextTextNode?.isSameNode(boundingNode)) {
			return nextTextNode;
		}

		// Check if the node is a hard break (<br />) or an element with role="presentation" we do one of two things:
		if ((nextTextNode as HTMLElement)?.tagName === 'BR') {
			// If it has a bounding parent, break out because the <br /> signifies the end of the selection
			if (boundingNode) {
				return nextTextNode;
			}
			// If not, then move to the next sibling because we don’t count hard breaks in selections
			nextTextNode = nextTextNode.nextSibling;
		} else if (
			(nextTextNode as HTMLElement)?.attributes?.getNamedItem('role')?.value === 'presentation'
		) {
			// If the element has role="presentation", move to the parent element's sibling because this current node does not contain a text node
			nextTextNode = nextTextNode.parentNode?.nextSibling;
		} else if (isEmojiNode(nextTextNode)) {
			// If we encounter an emoji and it's also the bounding node, return nothing
			if (boundingNode && nextTextNode?.isSameNode(boundingNode)) {
				return null;
			}

			// Otherwise, just return the node after it and keep going
			nextTextNode = nextTextNode.nextSibling;
		} else {
			// Otherwise, search for the first text node in the next node
			nextTextNode = nextTextNode.firstChild;
		}
	}

	// If we’ve found the text node, return that node
	if (nextTextNode?.nodeType === Node.TEXT_NODE) {
		return nextTextNode;
	}

	// If the text node we tried to access is null, we need to jump up a generation
	if (!nextTextNode) {
		return getNextTextNode(currentNode?.parentNode, boundingNode);
	}
};

const extractTextNodesIntoRangesOld = (selectedRange: Range) => {
	const rangesToHighlight: Range[] = [];
	const { startContainer, startOffset, endContainer, endOffset } = selectedRange;

	// NOTE: It's not possible for us to get here with anything other than "#text" nodes
	//       for the start container due to our highlighting restrictions, so we only
	//       need to check the type for endContainer when we get a selected range

	// WS-2551 - Triple clicks are the only thing that can cause the endContainer to be a non-text node
	const isTripleClick = endContainer.nodeType !== Node.TEXT_NODE;

	// If the start and end containers don't match, we need to loop through and extract
	// the text nodes out all common elements and create ranges to represent each of them
	if (!startContainer.isSameNode(endContainer)) {
		let boundingParentNode: Node | null | undefined;
		// Push the starting node into a range
		const startingRange = new Range();
		startingRange.setStart(startContainer, startOffset);
		startingRange.setEnd(startContainer, (startContainer as Text).length);
		rangesToHighlight.push(startingRange);

		// WS-2551 - In the case of a triple click, we only want to contain our selection to the
		// first non-comment parent wrapping the starting container to ensure we don't over select
		if (isTripleClick) {
			boundingParentNode = startContainer.parentNode;
			while (
				!boundingParentNode ||
				boundingParentNode.nodeType === Node.TEXT_NODE ||
				getMarkerRef(boundingParentNode as HTMLElement) !== null
			) {
				boundingParentNode = boundingParentNode?.parentNode;
			}
		} else {
			// Push the ending node into a range only if it's not a triple click
			const endingRange = new Range();

			endingRange.setStart(endContainer, 0);
			endingRange.setEnd(endContainer, endOffset);
			rangesToHighlight.push(endingRange);
		}

		// Iterate through all the inner nodes and extract the text ranges
		let node = getNextTextNodeOld(startContainer, boundingParentNode);
		while (node && !node.isSameNode(endContainer) && node.nodeType === Node.TEXT_NODE) {
			if ((node as Text).length) {
				const range = new Range();
				range.selectNodeContents(node);
				rangesToHighlight.push(range);
			}

			node = getNextTextNodeOld(node, boundingParentNode);
		}
	} else {
		// If they are the same container it's much simpler and we can just use the range
		rangesToHighlight.push(selectedRange);
	}

	return rangesToHighlight;
};

const isEmojiNode = (node?: Node) => {
	if (!node) {
		return false;
	}

	const attributes = (node as HTMLElement)?.attributes;
	const classList = (node as HTMLElement)?.classList;

	return (
		(attributes && attributes.getNamedItem('data-emoji-id')) ||
		(classList && classList.contains('emoji-common-node'))
	);
};

const extractTextNodesIntoRanges = (selectedRange: Range) => {
	const rangesToHighlight: Range[] = [];
	const { startContainer, startOffset, endContainer, endOffset } = selectedRange;

	// NOTE: It's not possible for us to get here with anything other than "#text" nodes
	//       for the start container due to our highlighting restrictions, so we only
	//       need to check the type for endContainer when we get a selected range

	// The endContainer is a non-text node when the user either:
	// 1. Triple clicks a node
	// 2. Highlights a header
	// 3. The highlight goes to the next node but does not actually highlight anything (offset === 0)
	const isNonTextNodeEndContainer = endContainer.nodeType !== Node.TEXT_NODE;

	// If highlight is just one node (i.e. same containers), it's much simpler and we can just use the range
	if (startContainer.isSameNode(endContainer)) {
		rangesToHighlight.push(selectedRange);
		return rangesToHighlight;
	}

	// If the start and end containers don't match, we need to loop through and extract
	// the text nodes out all common elements and create ranges to represent each of them
	let boundingNode: Node | null | undefined;
	// Push the starting node into a range
	const startingRange = new Range();
	startingRange.setStart(startContainer, startOffset);
	startingRange.setEnd(startContainer, (startContainer as Text).length);
	rangesToHighlight.push(startingRange);

	// In the case of a non-text node endContainer, we want to set a bounding node to the endContainer so we know
	// to stop traversing the DOM when hidding that bounding node
	if (isNonTextNodeEndContainer) {
		// If the end container is non-text and an emoji, we need to jump up a level or else it is
		// going to be bound to a node that we will never be able to check and thus never exit
		if (isEmojiNode(endContainer)) {
			boundingNode = endContainer.parentNode;
		} else {
			boundingNode = endContainer;
		}
	} else {
		// Push the ending node into a range
		const endingRange = new Range();
		endingRange.setStart(endContainer, 0);
		endingRange.setEnd(endContainer, endOffset);
		rangesToHighlight.push(endingRange);
	}

	// Get the first "inner" node between the start and end containers
	let node = getNextTextNode(startContainer, boundingNode);

	// Iterate through all the inner nodes and extract the text ranges
	while (node && !node.isSameNode(endContainer) && node.nodeType === Node.TEXT_NODE) {
		if ((node as Text).length) {
			const range = new Range();
			range.selectNodeContents(node);
			rangesToHighlight.push(range);
		}

		node = getNextTextNode(node, boundingNode);
	}

	return rangesToHighlight;
};

export const getHighlightOffset = (
	isFabricPage: boolean,
	isCreateDialogOpen: boolean = false,
	markerRef: string | undefined = '',
) => {
	const highlightClass = CREATE_COMMENT_SELECTION_CLASS;
	let markOffsetTop = 0;

	// Measure the offset of the highlight from the renderer wrapper
	// WS-2618 We have old macros that render tiny inline comments so we need to look for both fabric and tiny marker refs
	if (markerRef) {
		const highlight: HTMLElement | null = getHighlightFromMarkerRef(markerRef);

		markOffsetTop = calculateElementOffset(highlight);
	} else {
		const selection: Selection | null = window.getSelection();

		// If there is a user selection, we need to create a new temp "highlight" for this
		// new selection as it is removed when the temp "highlight" is created
		const isNewSelection = selection && selection.type == 'Range' && !isCreateDialogOpen;
		if (isNewSelection) {
			// Reset any existing DOM selections
			resetTemporaryDOMHighlight();

			// Mark the selection in the DOM temporarily
			createTemporaryDOMHighlight(false);
		}

		const createCommentHighlight: HTMLElement | null = document.querySelector(`.${highlightClass}`);

		markOffsetTop = calculateElementOffset(createCommentHighlight);
	}

	// On a fabric page, the highlight is positioned relative to the renderer
	// wrapper. To correctly position the sidebar we need to measure the offset
	// of the renderer wrapper and the offset of the highlight from the wrapper
	if (isFabricPage) {
		const rendererWrapper: HTMLElement | null = document.querySelector(
			'#content .ak-renderer-wrapper',
		);

		if (rendererWrapper) {
			markOffsetTop += rendererWrapper.offsetTop;
		}
	} else {
		// In tiny, we need to remove the title and author info offset to position it correctly
		const pageMetadata: HTMLElement | null = document.querySelector(
			'#content-body .page-metadata-modification-info',
		);

		if (pageMetadata) {
			markOffsetTop -= pageMetadata.offsetTop - pageMetadata.offsetHeight;
		}
	}

	return markOffsetTop;
};

export const createTemporaryDOMHighlight = (useNewHighlightLogic?: boolean) => {
	const highlightClass = CREATE_COMMENT_SELECTION_CLASS;
	const selection = window.getSelection();

	if (!selection || selection.type !== 'Range') {
		// Throw error?
		return;
	}

	// Extract the range
	const selectedRange = selection.getRangeAt(0);

	// Get an array of the ranges to make highlights
	const rangesToHighlight = useNewHighlightLogic
		? extractTextNodesIntoRanges(selectedRange)
		: extractTextNodesIntoRangesOld(selectedRange);

	rangesToHighlight.forEach((range) => {
		// Create a new wrapper element with the correct class
		const highlightWrapper = document.createElement('span');
		highlightWrapper.classList.add(highlightClass);
		// Extract the contents of the range and add it to the new wrapper
		highlightWrapper.appendChild(range.extractContents());

		// Re-insert the wrapper back into the range
		range.insertNode(highlightWrapper);
	});

	// Clear the selection
	selection.removeAllRanges();
};

const unwrapTextNodes = (wrappedNodes: Element[]) => {
	while (wrappedNodes.length) {
		const node = wrappedNodes.shift();

		if (!node) {
			return;
		}

		const { parentNode } = node;

		// Extract the text contents of the selection node out of the temp wrapper
		while (node.firstChild) {
			parentNode?.insertBefore(node.firstChild, node);
		}

		// After the text has been extracted, delete the wrapper
		parentNode?.removeChild(node);

		// Normalize the parent to fix any text node fragmentation
		parentNode?.normalize();
	}
};

export const resetTemporaryDOMHighlight = () => {
	const highlightClass = CREATE_COMMENT_SELECTION_CLASS;
	// Grab all the newly created selection nodes
	const tempSelectionNodes = Array.from(document.querySelectorAll(`.${highlightClass}`));

	unwrapTextNodes(tempSelectionNodes);
};

export const convertDOMHighlightToMark = (
	isFabricPage: boolean,
	markerRef: string | null,
	isActive = true,
) => {
	const createCommentSelectionNodes = document.querySelectorAll(
		`.${CREATE_COMMENT_SELECTION_CLASS}`,
	);

	const ref = markerRef || '';

	// If we find selection nodes in the DOM, we need to add some attributes to them,
	// based on the page type, so they behave correctly when the user clicks a highlight
	if (createCommentSelectionNodes.length) {
		createCommentSelectionNodes.forEach((selectionNode) => {
			if (isFabricPage) {
				selectionNode.setAttribute(FABRIC_PAGE_MARKER_REF_ATTRIBUTE, ref);
				selectionNode.setAttribute('data-mark-annotation-type', 'inlineComment');
			} else {
				selectionNode.setAttribute(TINY_PAGE_MARKER_REF_ATTRIBUTE, ref);
				selectionNode.classList.add('inline-comment-marker');
			}

			const classesToAdd = [HIGHLIGHT_CLASS];

			if (isActive) {
				classesToAdd.push(ACTIVE_CLASS);
			}

			// Add the classes needed
			selectionNode.classList.add(...classesToAdd);

			// Finally, remove the selection class to make it a full mark
			selectionNode.classList.remove(CREATE_COMMENT_SELECTION_CLASS);
		});
	}
};

const setHighlightsAsActive = (highlightElement: Element, skipClassCheck = false) => {
	if (skipClassCheck || highlightElement.classList.contains(HIGHLIGHT_CLASS)) {
		highlightElement.classList.add(ACTIVE_CLASS);
	}

	// We need to set all the children of this highlight to active as well
	const { children } = highlightElement;
	for (let i = 0; i < children.length; ++i) {
		setHighlightsAsActive(children[i], skipClassCheck);
	}
};

/* We cannot just set the active state on the element that was clicked due to the
 * fact that a highlight can span multiple elements and even other comments in the
 * DOM depending on when they are added. For example, we can have a DOM structure that
 * looks like this:
 *
 *    <p>
 *      Some text
 *      <span data-id="marker-ref-1" class="inline-highlight">
 *        An important
 *        <span data-id="marker-ref-2" class="inline-highlight">
 *          passage that was highlighted
 *        </span>
 *        by the user
 *      </span>
 *      with complicated higlights
 *    </p>
 *    <p>
 *      <span data-id="marker-ref-3" class="inline-highlight">
 *        <span data-id="marker-ref-1" class="inline-highlight">
 *          Text in the next
 *        </span>
 *        paragraph that is also highlighted
 *      </span>
 *      And unselected
 *    </p>
 *
 * Lets say the user tries to select the comment with markerRef "marker-ref-1" that spans
 * text across both paragraphs which should result in the text "An important passage that
 * was highlighted by the user with complicated highlightsText in the next" being activated.
 * We need to activate all the nodes of that we can find with the matching markerRef, but
 * then ALSO all of the nodes that are children of those parents which contain other highlights.
 * The user will see gaps if we do not properly add the active class to ALL of these nodes.
 */
export const setCommentAsActive = (markerRef: string | null, skipClassCheck = false) => {
	const highlightMarks = getAllHighlightsFromMarkerRef(markerRef) || [];

	highlightMarks.forEach((highlight) => setHighlightsAsActive(highlight, skipClassCheck));
};

export const isHighlightActive = (markerRef: string | null) => {
	const highlight = getHighlightFromMarkerRef(markerRef || '');

	return Boolean(highlight && highlight.classList.contains(ACTIVE_CLASS));
};

export const removeMarkInDOM = (markerRef: string | null) => {
	const highlightMarksToRemove = Array.from(getAllHighlightsFromMarkerRef(markerRef));

	unwrapTextNodes(highlightMarksToRemove);
	clearActiveHighlight();
};

export const getUnresolvedAnnotations = (): Set<string> => {
	const highlightedAnnotations = Array.from(
		document.querySelectorAll('.ak-editor-annotation-blur, .ak-editor-annotation-focus'),
	);
	const highlightedAnnotationIds = highlightedAnnotations
		.map((annotation) => annotation?.closest('.annotationView-content-wrap')?.id)
		.filter((id): id is string => Boolean(id));

	return new Set(highlightedAnnotationIds);
};

type ADF = {
	type: string;
	content: any[];
	version: number;
} | null;

export const FABRIC_PAGE_MARKER_REF_ATTRIBUTE = 'data-id';
export const TINY_PAGE_MARKER_REF_ATTRIBUTE = 'data-ref';

const hasAdfContent = (input: ADF) => {
	const content = input && input.content;
	return content && content.some(validateContent);
};

// Check to make sure if we have item.type as paragraph content is not empty (user did not type anything)
// or user has put in blank spaces(empty string) and clicked save
// Any type other than paragraph is valid (e.g:- when user inserts divider, status etc without content)
const validateContent = (item: any) => item.type !== 'paragraph' || validateParagraph(item);

const validateParagraph = (item: any) => {
	if (item.content) {
		return item.content.some(isParagraphNotEmpty);
	}

	return false;
};

const isParagraphNotEmpty = (item: any) => item.type !== 'text' || item.text.trim() !== '';

export const isEmptyAdf = (adf: ADF) => !adf || !hasAdfContent(adf);

export const getMarkerRefAttribute = (isFabricPage: any) =>
	isFabricPage ? FABRIC_PAGE_MARKER_REF_ATTRIBUTE : TINY_PAGE_MARKER_REF_ATTRIBUTE;

// WS-2618 We have old macros that still render out tiny comments, so we need to check for both to get the marker ref
export const getMarkerRef = (element: HTMLElement | null) => {
	let markerRef: string | null = null;
	if (element) {
		markerRef = element.getAttribute(FABRIC_PAGE_MARKER_REF_ATTRIBUTE);
		if (!markerRef) {
			markerRef = element.getAttribute(TINY_PAGE_MARKER_REF_ATTRIBUTE);
		}
	}

	return markerRef;
};

// WS-2618 We have old macros that still render out tiny comments, so we need to check for both marker refs to get a comment
export const getHighlightFromMarkerRef: (
	markerRef?: string,
	additionalQueryScope?: string,
) => HTMLElement | null = (markerRef, additionalQueryScope = '') => {
	if (!markerRef) {
		return null;
	}

	// We need to allow for additional query scope to be added to specify particular markerRef comments
	return document.querySelector(
		`${additionalQueryScope} [${FABRIC_PAGE_MARKER_REF_ATTRIBUTE}="${markerRef}"], ${additionalQueryScope} [${TINY_PAGE_MARKER_REF_ATTRIBUTE}="${markerRef}"]`,
	);
};

// WS-2618 We have old macros that still render out tiny comments, so we need to check for both marker refs to get a comment
export const getAllHighlightsFromMarkerRef = (markerRef: any) => {
	return document.querySelectorAll(
		`[${FABRIC_PAGE_MARKER_REF_ATTRIBUTE}="${markerRef}"], [${TINY_PAGE_MARKER_REF_ATTRIBUTE}="${markerRef}"]`,
	);
};

// List of keys to allow propagation in the comment editor
export const keyPropagationList = ['enter', 'escape'];

const getDimensions = () => {
	try {
		return {
			currentWindowHeight: window.innerHeight || (window.top?.innerHeight ?? 0),
			currentPageOffset: window.scrollY || (window.top?.scrollY ?? 0),
		};
	} catch {
		// Catch cross origin iframe error https://hello.atlassian.net/browse/CBT-907
		return { currentWindowHeight: 0, currentPageOffset: 0 };
	}
};

const getDimensionsForNav4 = () => {
	try {
		const nav4ScrollParent = document.getElementById('AkMainContent');
		const currentPageOffset = !!nav4ScrollParent
			? nav4ScrollParent.scrollTop
			: window.scrollY || (window.top?.scrollY ?? 0);

		return {
			currentWindowHeight: window.innerHeight || (window.top?.innerHeight ?? 0),
			currentPageOffset,
		};
	} catch {
		// Catch cross origin iframe error https://hello.atlassian.net/browse/CBT-907
		return { currentWindowHeight: 0, currentPageOffset: 0 };
	}
};

const COMMENT_CONTAINER_OFFSET = 350;

// @TODO PGEXP-351 follow-up: We probably need to change this behavior for Nav4
export const scrollPageCommentIntoView = (
	commentElement: HTMLElement | null,
	useInstantScroll = false,
	isAbove = false,
) => {
	if (!commentElement) {
		return;
	}

	const { currentWindowHeight, currentPageOffset } = getDimensions();

	const offset =
		calculateElementOffset(commentElement) +
		commentElement.offsetHeight +
		(isAbove ? -COMMENT_CONTAINER_OFFSET : 0);

	// If the position of the comment box is more than halfway down the screen, reposition it to the center
	if (offset <= currentPageOffset || offset > currentPageOffset + currentWindowHeight * (2 / 3)) {
		const windowOffset = currentWindowHeight / 5;
		window.scrollTo({
			top: offset - windowOffset,
			behavior: useInstantScroll ? ('instant' as ScrollBehavior) : 'smooth', // focused comments use an instant scroll
		});
	}
};

// scrolls to the element
export const handleScrollToElement = ({
	commentId,
	targetCommentId,
	isEditor,
	viewingRoomOffset = -150,
	useInstantScroll = false,
}: {
	commentId: string;
	targetCommentId: string;
	isEditor: boolean;
	viewingRoomOffset?: number;
	useInstantScroll?: boolean;
}) => {
	if (commentId !== targetCommentId) {
		return;
	}

	const commentElement = document.querySelector(`[data-comment-id="${commentId}"]`) as HTMLElement;
	if (isEditor && commentElement) {
		scrollToElementInEditor({ targetElement: commentElement, viewingRoomOffset });
	} else {
		scrollToElementWithViewingRoom({
			element: commentElement,
			viewingRoomOffset,
			useInstantScroll,
		});
	}
};

// Function to scroll a comment into view with the correct offsets
export const scrollCommentIntoView = ({
	commentElement,
	isFabricPage,
	useInstantScroll = false,
	isAbove = false,
	commentThreadContainerId,
	isCommentsPanel,
}: ScrollCommentIntoViewType) => {
	if (!commentElement) {
		return;
	}

	const isNav4Enabled = getIsNav4Enabled();
	const nodeToConsider = getNodeForTableORMacro(commentElement) || commentElement;
	const targetCommentHighlight = nodeToConsider.getBoundingClientRect();
	const { scrollRootElement, getDimensions } = getScrollRootContext(isNav4Enabled);
	const { currentWindowHeight, currentPageOffset } = getDimensions();
	if (isCommentsPanel) {
		const element = document.querySelector(
			`[data-testid='${commentThreadContainerId}']`,
		) as HTMLElement;

		const commentThreadParentElement = element.getBoundingClientRect().top;

		const commentThreadSelector = document.querySelector(
			`[data-testid='comments-panel-list-container']`,
		);

		const inlineCommentPosition = element?.getBoundingClientRect();

		const documentContent = document.getElementById('content');
		const documentContentTopPosition = documentContent?.getBoundingClientRect().top;

		const commentAboveViewport =
			inlineCommentPosition &&
			documentContentTopPosition &&
			Math.floor(inlineCommentPosition.top) < Math.floor(documentContentTopPosition);

		const commentBelowViewport =
			inlineCommentPosition && inlineCommentPosition.top + 200 > window.innerHeight;

		const commentHighlightAboveViewPort =
			Math.floor(targetCommentHighlight.bottom) < currentPageOffset;
		const commentHighlightBelowViewPort = targetCommentHighlight.top + 200 > window.innerHeight;

		/**
		 * Determine which panel or section needs to be scrolled:
		 * the content area or the comments panel.
		 * We need to find out if the highlight or panel is above or below the viewport.
		 * This will help us decide which one to scroll.
		 */
		if (commentHighlightAboveViewPort || commentHighlightBelowViewPort) {
			// if the comment highlight is above or below viewport use content area to scroll;
			scrollRootElement.scrollBy({
				top:
					window.innerHeight -
					commentThreadParentElement -
					(window.innerHeight - targetCommentHighlight.top),
				behavior: useInstantScroll ? ('instant' as ScrollBehavior) : 'smooth',
			});
		} else if (
			commentThreadSelector &&
			(commentAboveViewport ||
				commentBelowViewport ||
				window.innerHeight -
					commentThreadParentElement -
					(window.innerHeight - targetCommentHighlight.top) <
					0)
		) {
			// if the comment thread is above or below viewport or
			// there is difference in top of comment thread and highlight if completely visible in the viewport
			//  use commentThreadSelector as an element to scroll ;
			commentThreadSelector.scrollBy({
				top:
					window.innerHeight -
					targetCommentHighlight.top -
					(window.innerHeight - commentThreadParentElement),
				behavior: useInstantScroll ? ('instant' as ScrollBehavior) : 'smooth',
			});
		} else {
			scrollRootElement.scrollBy({
				top: targetCommentHighlight.top - commentThreadParentElement,
				behavior: useInstantScroll ? ('instant' as ScrollBehavior) : 'smooth',
			});
		}
	} else {
		// We may be in an iframe so use window.top
		const offset =
			calculateElementOffset(commentElement) +
			commentElement.offsetHeight +
			(isAbove ? -COMMENT_CONTAINER_OFFSET : 0);
		const rendererOffset =
			(document.querySelector('#content .ak-renderer-wrapper') as HTMLElement)?.offsetTop || 0;

		// If the position of the highlight is more than halfway down the screen, reposition it to the center
		if (
			offset + rendererOffset <= currentPageOffset ||
			offset + rendererOffset > currentPageOffset + currentWindowHeight * (2 / 3)
		) {
			const windowOffset = isFabricPage ? currentWindowHeight / 5 : currentWindowHeight / 3;

			scrollRootElement.scrollTo({
				top: offset - windowOffset,
				behavior: useInstantScroll ? ('instant' as ScrollBehavior) : 'smooth', // focused comments use an instant scroll
			});
		}

		//If comment is in expand, open respective expand
	}
	checkElementExpand(commentElement, isFabricPage);
};
/**
 * Scroll an element into view if element is out of visible area.
 * Visible area is defined as scroll container with 60px threshold at top and bottom.
 * This implementation is mimicking how prosemirror scrolls elements into view.
 */
export const scrollTargetIntoViewIfNeeded = (target: Element, scrollContainer: Element) => {
	const rect = target.getBoundingClientRect();
	const parentRect = scrollContainer.getBoundingClientRect();
	let moveY = 0;

	// The distance (in pixels) between the target and the end of the visible viewport at which point,
	// when scrolling the target into view, scrolling takes place.
	const SCROLL_THRESHOLD = 60;
	// The extra space (in pixels) that is left above or below the target when it is scrolled into view.
	const SCROLL_MARGIN = 120;

	// Note: visible area of the container refers to parentRect.top/bottom +/- SCROLL_THRESHOLD
	if (rect.top < parentRect.top + SCROLL_THRESHOLD) {
		// If the target is above the top of the visible area of the container, moveY should be negative to bring target down into view
		moveY = -(parentRect.top - rect.top + SCROLL_MARGIN);
	} else if (rect.bottom > parentRect.bottom - SCROLL_THRESHOLD) {
		// If the target's bottom is below the bottom of the visible area of the container, move Y should be positive to bring target up into view
		moveY = rect.bottom - parentRect.bottom + SCROLL_MARGIN;
	}

	if (moveY) {
		scrollContainer.scrollTop += moveY;
	}
};

// I understand the appeal to dynamically generate things like this, but when strings are used as IDs or keys,
// it's more important to be explicit about those keys than smart in code. If there's a bug with this component,
// the id is the thing people will search for; and if it's dynamically generated, it's impossible to find.
export const getSidebarId = (isEditor?: boolean, isViewCommentMode?: boolean) => {
	if (isEditor && isViewCommentMode) {
		return 'editor-comments-sidebar';
	} else if (isEditor && !isViewCommentMode) {
		return 'editor-create-sidebar';
	} else if (!isEditor && isViewCommentMode) {
		return 'renderer-comments-sidebar';
	} else {
		return 'renderer-create-sidebar';
	}
};

// Navigate between comments using the correct offsets
export const scrollToComment = (
	commentElement: HTMLElement | null,
	previousCommentElement: CommentElement,
	isFabricPage: any,
	isEditor?: boolean,
) => {
	if (!commentElement) return;

	const isNav4Enabled = getIsNav4Enabled();
	const { scrollRootElement, scrollRootOffset } = getScrollRootContext(isNav4Enabled);

	// if current open comment is out of viewport, we need to scroll so that the next comment is in viewport
	const sidebarId = getSidebarId(isEditor, true); // Hard-coding isViewCommentMode to true because navigation only exists in the view scenario
	const inlineCommentSidebar = document.getElementById(sidebarId);
	const inlineCommentPosition = inlineCommentSidebar?.getBoundingClientRect();

	const documentContent = document.getElementById('content');
	const documentContentTopPosition = documentContent?.getBoundingClientRect().top;

	const commentAboveViewport =
		inlineCommentPosition &&
		documentContentTopPosition &&
		inlineCommentPosition.top < documentContentTopPosition + scrollRootOffset;

	const commentBelowViewport =
		inlineCommentPosition && inlineCommentPosition.top + 200 > window.innerHeight;

	if (commentAboveViewport || commentBelowViewport) {
		scrollCommentIntoView({ commentElement, isFabricPage });
		return;
	}

	const previousOffset = previousCommentElement
		? calculateElementOffset(previousCommentElement)
		: 0;

	const offset = calculateElementOffset(commentElement);

	const differenceOffset = offset - previousOffset;
	scrollRootElement.scrollBy({
		top: differenceOffset,
		behavior: 'smooth', // navigation uses a smooth scroll
	});

	// If comment is in expand, open respective expand
	checkElementExpand(commentElement, isFabricPage);
};

const adjustNodeForStickyHeader = (stickyHeaderElement: HTMLElement | null) => {
	let adjustedElement = stickyHeaderElement;

	const markerRef = getMarkerRef(adjustedElement);

	// If this is exists it's a commited comment, look for its counterpart in the real table
	// TODO: Support new highlights when we allow it
	if (markerRef) {
		// WS-2618 We have old macros that render tiny inline comments so we need to look for both fabric and tiny marker refs
		adjustedElement = getHighlightFromMarkerRef(markerRef, '.pm-table-wrapper');
	}

	return adjustedElement;
};

export const getNodeForTableORMacro = (element: HTMLElement | null | undefined) => {
	let nodeToReturn = element;

	// Sticky table headers duplicate the elements in the DOM so we need to check if this is the real one or not
	// If the highlight has a parent with the class .pm-table-sticky-wrapper it is the fake one
	if (nodeToReturn && nodeToReturn.closest && nodeToReturn.closest('.pm-table-sticky-wrapper')) {
		nodeToReturn = adjustNodeForStickyHeader(nodeToReturn);
	}

	if (nodeToReturn) {
		// WS-2718 - If a comment is in an extension macro
		const extensionParent = nodeToReturn.closest('.ak-renderer-extension') as HTMLElement | null;

		if (extensionParent) {
			nodeToReturn = extensionParent;
		}
	}
	return nodeToReturn;
};

// Function to calculate a comment's offset taking nested elements into consideration
// relativeToElement allows the caller to optionally specify an element
// in `element`'s offsetParents to stop the calculation early at.
// This allows an easy way to calculate offset relative to a certain container, for example, the Editor.
export const calculateElementOffset = (
	element: HTMLElement | null | undefined,
	relativeToElement?: HTMLElement | null | undefined,
	isRendererAnnotationProviderEnabled?: boolean,
) => {
	let elementOffsetTop = 0;
	let nodeToMeasure = element;

	// Sticky table headers duplicate the elements in the DOM so we need to check if this is the real one or not
	// If the highlight has a parent with the class .pm-table-sticky-wrapper it is the fake one
	if (nodeToMeasure && nodeToMeasure.closest && nodeToMeasure.closest('.pm-table-sticky-wrapper')) {
		// The RAP annotation draft mark is applied to the both pm-table-sticky-wrapper as well as the pm-table-wrapper.
		// Need to check only pm-table-wrapper annotation draft mark for the RAP enabled
		nodeToMeasure = isRendererAnnotationProviderEnabled
			? (document.querySelector(
					`.pm-table-wrapper [data-annotation-draft-mark="true"]`,
				) as HTMLElement | null)
			: adjustNodeForStickyHeader(nodeToMeasure);
	}

	// Iterate through the offset parents until we are back to the ak-renderer-wrapper to get the
	// proper offset Fabric Pages wrap the content in a .ak-renderer-wrapper element, but Tiny
	// pages do not have anything like that, but will return null when we get to <body>
	while (
		nodeToMeasure &&
		!nodeToMeasure.classList.contains('ak-renderer-wrapper') &&
		nodeToMeasure !== relativeToElement
	) {
		elementOffsetTop += nodeToMeasure.offsetTop;
		nodeToMeasure = nodeToMeasure.offsetParent as HTMLElement | null;
	}

	// WS-2718 - If a comment is in an extension macro, we need to add its container offset as well
	if (nodeToMeasure) {
		const extensionParent = nodeToMeasure.closest('.ak-renderer-extension') as HTMLElement | null;

		if (extensionParent) {
			elementOffsetTop += extensionParent.offsetTop;
		}
	}

	return elementOffsetTop;
};

//Helper function to determine if passed in element has a child expand button
//Returns the button element if true, otherwise null
export const hasExpandButton = (element: HTMLElement, isFabricPage?: boolean) => {
	//get the child of element passed in
	const expandElement = element?.hasChildNodes() ? (element?.firstChild as HTMLElement) : element;

	//check if element is expand button and return element if so
	if (isFabricPage) {
		if (expandElement?.tagName?.toLowerCase() === 'button') {
			return expandElement;
		}
	} else if (expandElement?.classList?.contains('expand-control')) {
		return expandElement?.firstChild as HTMLElement; //it's one child down for tiny pages
	}
	return null;
};

// Function to determine if comment is within expand, and open respective expand
export const checkElementExpand = (element: HTMLElement, isFabricPage?: boolean) => {
	let nodeToCheck = element;
	const expandElementClassname = isFabricPage ? '.expand-content-wrapper' : '.expand-content';
	const nestedExpandElementClassname = '.nestedExpand-content-wrapper';
	let expandButton: HTMLElement | null = null;

	//check if comment is in expand
	if (
		nodeToCheck?.closest?.(expandElementClassname) ||
		nodeToCheck?.closest?.(nestedExpandElementClassname)
	) {
		//find comment's respective expand button
		while (nodeToCheck) {
			expandButton = hasExpandButton(nodeToCheck, isFabricPage);
			if (hasExpandButton(nodeToCheck, isFabricPage)) {
				break;
			} else {
				nodeToCheck = nodeToCheck?.parentElement as HTMLElement;
			}
		}
		//check if expand is already opened, if not open it
		if (expandButton) {
			if (
				(isFabricPage && expandButton.getAttribute('aria-expanded') === 'false') ||
				(!isFabricPage && expandButton.className.includes('expanded') === false)
			) {
				expandButton.click();
			}
		}
	}
};

export const getNextMarkerRef = (
	action: 'next' | 'previous',
	index: number | undefined,
	markerRefList: string[],
): string | void => {
	if (markerRefList.length === 0 || index === undefined || index === -1) {
		return;
	}
	let commentIndex = -1;
	//Navigate to first inline comment if we're on the last comment and the user clicks next,
	// or to the last comment if we're on the first comment and the user clicks previous
	if (action === 'next') {
		commentIndex = index === markerRefList.length - 1 ? 0 : index + 1;
	} else if (action === 'previous') {
		commentIndex = index === 0 ? markerRefList.length - 1 : index - 1;
	}

	return markerRefList[commentIndex];
};

export const getNextUnreadMarkerRef = (
	index: number | undefined,
	markerRefList: string[],
	unreadMarkerRefList?: string[],
) => {
	if (
		index === undefined ||
		markerRefList.length === 0 ||
		index === -1 ||
		!unreadMarkerRefList ||
		unreadMarkerRefList.length === 0
	) {
		return;
	}
	let commentIndex = index;
	const refListLength = markerRefList.length;
	let iterations = 0;
	while (
		markerRefList[commentIndex] !== undefined &&
		!unreadMarkerRefList.includes(markerRefList[commentIndex]) &&
		iterations < refListLength
	) {
		commentIndex = (commentIndex + 1) % markerRefList.length;
		iterations++;
	}
	return markerRefList[commentIndex];
};

/** Helper functions for clipboard api */

export const isClipboardApiSupported = (): boolean =>
	navigator.clipboard && typeof navigator.clipboard.writeText === 'function';

/** In the IE, window contain clipboard api instead of navigator. */
export const isIEClipboardApiSupported = (): boolean =>
	(window as any).clipboardData && typeof (window as any).clipboardData.setData === 'function';

export const copyToClipboardIE = (textToCopy: string): Promise<void> =>
	new Promise<void>((resolve: () => void, reject: (str: string) => void) => {
		if (isIEClipboardApiSupported()) {
			(window as any).clipboardData.setData('text', textToCopy);
			resolve();
		} else {
			reject('IE clipboard api is not supported');
		}
	});

export const copyToClipboard = async (text: string): Promise<void> => {
	if (isClipboardApiSupported()) {
		await navigator.clipboard.writeText(text);
	}
};

export const legacyCopyToClipboard = (text: string, ref: HTMLDivElement | null): Promise<void> =>
	new Promise<void>((res, rej) => {
		if (!ref) {
			rej();
			return;
		}

		const textarea = document.createElement('textarea');
		textarea.readOnly = true;
		textarea.defaultValue = text;
		ref.appendChild(textarea);
		textarea.select();
		const wasCopied = document.execCommand('copy');
		ref.removeChild(textarea);

		wasCopied ? res() : rej();
	});

// for both editor and live pages
export const calculateEditorOffsetFromParent = (
	annotationElement: HTMLElement | null | undefined,
	scrollParentSelector: string,
) => {
	const scrollParent = document.querySelector(scrollParentSelector) as HTMLElement;
	return calculateElementOffset(annotationElement, scrollParent);
};

export const calculateRendererOffsetFromParent = (
	annotationElement: HTMLElement | null | undefined,
	scrollParentSelector: string,
	isView: boolean,
) => {
	let offset = 0;
	if (annotationElement) {
		// If the annotation is the wrapperDOM, we actually need #content to be the scrollParent
		const scrollParent = document.querySelector(
			'div#content[data-inline-comments-target="true"]',
		) as HTMLElement;

		if (scrollParent) {
			let annotationOffset: number;

			if (isView) {
				annotationOffset = calculateElementOffset(annotationElement);
			} else {
				// If the annotation is the wrapperDOM, we need to find the actual highlight instead
				const actualHiglightElement: HTMLElement | null =
					scrollParent.querySelector(scrollParentSelector);
				annotationOffset = calculateElementOffset(actualHiglightElement, undefined, true);
			}
			offset = annotationOffset + scrollParent.offsetTop;
		}
	}
	return offset;
};

/**
 *
 * @param entries
 * @returns top OR undefined
 */

export const getCommentPositionTopOfSection = (
	entries: ResizeObserverEntry[],
): number | undefined => {
	// The calculation is
	// 15px of marginTop on the comment container
	// 2px full-page-editor-style bottom which above the viewport
	// 1px border which is cumulates to 120px
	const thresholdTop = 102;

	const editorEntry = entries.find((entry) => entry.target.id === 'ak-editor-textarea');
	const editorBottom = editorEntry?.target.getBoundingClientRect().bottom;

	const editorContentHeight = document
		.querySelector(`.ak-editor-content-area .ProseMirror`)
		?.getBoundingClientRect().height;
	const documentContentBottom = document
		.querySelector(`[data-testid='full-page-editor-style']`)
		?.getBoundingClientRect().bottom;

	const windowHeight = window.innerHeight;

	if (editorContentHeight) {
		// If the document content is larger than the window height, just set it to a static 120 to keep the proper spacing
		if (editorContentHeight > windowHeight) {
			return 120;
		}

		if (documentContentBottom && editorBottom) {
			const verticalDistance = documentContentBottom - editorBottom;
			if (verticalDistance >= thresholdTop && editorContentHeight <= windowHeight) {
				return verticalDistance - thresholdTop;
			} else if (verticalDistance <= thresholdTop) {
				return thresholdTop;
			}
		}
	}
};

export const scrollToElementWithViewingRoom = ({
	element,
	viewingRoomOffset = -150,
	useInstantScroll = false,
}: {
	element: HTMLElement;
	viewingRoomOffset?: number;
	useInstantScroll?: boolean;
}) => {
	if (!element) return;

	const isNav4Enabled = getIsNav4Enabled();
	const { useNav4ScrollRoot, scrollRootElement, scrollRootOffset } =
		getScrollRootContext(isNav4Enabled);

	const elementRect = element.getBoundingClientRect();
	const elementTopViewport = elementRect.top;

	const scrollRootTopViewport = useNav4ScrollRoot
		? (scrollRootElement as HTMLElement).getBoundingClientRect().top
		: 0;
	const elementTopFromScrollRoot = elementTopViewport - scrollRootTopViewport;

	const targetScrollPosition = elementTopFromScrollRoot + scrollRootOffset + viewingRoomOffset;
	scrollRootElement.scrollTo({
		top: targetScrollPosition,
		behavior: useInstantScroll ? ('instant' as ScrollBehavior) : 'smooth', // focused comments use an instant scroll
	});

	// open expand if the element is inside an expand
	checkElementExpand(element, true);
};

/**
 * Returns the HTMLElement or window that should be used as the scroll root. By default, this is the window.
 * For Nav 4, this is the div with id=AkMainContent, if the id exists in the DOM
 *
 * @param isNav4Enabled - boolean
 */
function getScrollRootContext(isNav4Enabled: boolean = false) {
	const nav4ScrollRootElement = document.getElementById('AkMainContent');
	const useNav4ScrollRoot = isNav4Enabled && !!nav4ScrollRootElement;

	const scrollRootElement = useNav4ScrollRoot ? nav4ScrollRootElement : window;

	const scrollRootOffset = useNav4ScrollRoot ? nav4ScrollRootElement.scrollTop : window.scrollY;

	return {
		useNav4ScrollRoot,
		scrollRootElement,
		scrollRootOffset,
		getDimensions: isNav4Enabled ? getDimensionsForNav4 : getDimensions,
	};
}
